<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group label="Выберите перевозчика">
          <b-form-select id="carrier" v-model="form.carrierId" :state="formErrors.carrierId">
            <b-form-select-option :value="null" :key="null">Выберите перевозчика</b-form-select-option>
            <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
              {{ carrier.carrierName }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Наименование и номер маршрута">
          <b-form-select id="route" v-model="form.routeId" :state="formErrors.routeId">
            <b-form-select-option :value="null" :key="null">Выберите маршрут</b-form-select-option>
            <b-form-select-option v-for="route in routes" :value="route.id" :key="route.id">
              {{ route.number }} - {{ route.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Транспортное средство">
          <b-form-select id="bus" v-model="form.vehicleId" :state="formErrors.vehicleId">
            <b-form-select-option :value="null" :key="null">Выберите ТС</b-form-select-option>
            <b-form-select-option v-for="vehicle in localVehicles" :value="vehicle.id" :key="vehicle.id">
              {{ vehicle.gosNumber }} - {{ vehicle.mark }} {{ vehicle.model }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Время выезда">
          <b-form-timepicker id="startTime"  v-model="form.startTime" class="mb-2" :state="formErrors.startTime" label-no-time-selected=""></b-form-timepicker>
        </b-form-group>
        <b-form-group label="Дата начала">
          <b-form-datepicker id="startDate" v-model="form.startDate" :start-weekday="1" class="mb-2" :state="formErrors.startDate" label-no-date-selected=""></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Дата окончания">
          <b-form-datepicker id="finishTime" v-model="form.finishTime" :start-weekday="1" class="mb-2" :state="formErrors.finishTime" reset-button label-reset-button="Сбросить" label-no-date-selected="" ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Тип повторения">
          <b-form-select id="bus" v-model="form.type" :state="formErrors.type">
            <b-form-select-option :value="null">Выберите тип повторения</b-form-select-option>
            <b-form-select-option :value="'everyday'">Каждый день</b-form-select-option>
            <b-form-select-option :value="'in_one_day'">Через день</b-form-select-option>
            <b-form-select-option :value="'odd_day'">Нечётные дни</b-form-select-option>
            <b-form-select-option :value="'even_day'">Четные дни</b-form-select-option>
            <b-form-select-option :value="'every_n_day'" >Каждые N дней</b-form-select-option>
            <b-form-select-option :value="'by_week_days'">По определенным дням недели</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <template v-if="form.type === 'every_n_day'">
          <b-form-group label="Повтор через N дней">
            <b-form-input type="number" v-model.number="form.everyNDay" :state="formErrors.everyNDay"/>
            <b-form-text>Укажите, через сколько дней повторять маршрут</b-form-text>
          </b-form-group>
        </template>
        <template v-if="form.type === 'by_week_days'">
          <b-form-group>
            <b-form-checkbox v-model="form.byWeekDays.Monday">Понедельник</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Tuesday">Вторник</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Wednesday">Среда</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Thursday">Четверг</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Friday">Пятница</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Saturday">Суббота</b-form-checkbox>
            <b-form-checkbox v-model="form.byWeekDays.Sunday">Воскресенье</b-form-checkbox>
            <b-form-text>Выход по дням недели</b-form-text>
            <b-form-text text-variant="danger">{{errors.byWeekDays}}</b-form-text>
          </b-form-group>
        </template>
        <b-form-radio-group
            v-model="selectedRestrict"
            aria-controls="ex-disabled-readonly"
        >
          <b-form-group>
            <b-form-radio value="exceptSeats" class="mb-2">Продавать все, кроме</b-form-radio>
            <b-form-input v-model="form.exceptSeats" :state="formErrors.exceptSeats" :disabled="selectedRestrict !== 'exceptSeats'"/>
            <b-form-text>Укажите места недоступные для продажи. Номера мест через запятую</b-form-text>
          </b-form-group>
          <b-form-group>
            <b-form-radio value="seatsOnly" class="mb-2">Продавать только</b-form-radio>
            <b-form-input v-model="form.seatsOnly" :state="formErrors.seatsOnly" :disabled="selectedRestrict !== 'seatsOnly'"/>
            <b-form-text>Укажите места доступные для продажи. Номера мест через запятую</b-form-text>
          </b-form-group>
        </b-form-radio-group>
      </b-col>
      <b-col md="12">
        <slot></slot>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import PERIODIC_TRIP from "@/forms/PERIODIC_TRIP";
import {mapState} from "vuex";
import {phoneFormat} from "@/utils/phone";

const WEEK_DAYS = () => ({
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false
})

const PERIODIC_TRIP_INIT_FORM = () => ({
  carrierId: null,
  routeId: null,
  vehicleId: null,
  startTime: null,
  startDate: null,
  finishTime: null,
  exceptSeats: '',
  seatsOnly: '',
  type: null,
  everyNDay: '',
  byWeekDays: WEEK_DAYS(),
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => PERIODIC_TRIP_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      selectedRestrict: 'exceptSeats',
      form: this.init,
      errors: { }
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      carriers: state => state.Carriers.items,
      routes: state => state.Routes.items,
      vehicles: state => state.Vehicles.items
    }),
    localVehicles: function() {
      const carrierId = this.form.carrierId;
      return this.vehicles.filter(vehicle => vehicle.carrierId === carrierId);
    },
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, {
          ...form,
          everyNDay: form.condition.repeatFromNDays ? form.condition.repeatFromNDays : '',
          byWeekDays: form.condition.daysOfWeek ? { ...WEEK_DAYS(), ...Object.fromEntries(form.condition.daysOfWeek.map(day => [day, true])) } : WEEK_DAYS()
        })

        if (this.form.seatsOnly) {
          this.selectedRestrict = 'seatsOnly'
        } else if(this.form.exceptSeats) {
          this.selectedRestrict = 'exceptSeats'
        }
    },
    phoneFormat: (value) => phoneFormat(value),
    resetForm() {
      Object.assign(this.form, PERIODIC_TRIP_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, PERIODIC_TRIP)
      console.log(this.errors)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      let form = { ...this.form }

      if (this.selectedRestrict === 'exceptSeats') {
        form.seatsOnly = ''
      } else if(this.selectedRestrict === 'seatsOnly') {
        form.exceptSeats = ''
      }

      form.startTime = form.startDate + ' ' + form.startTime
      delete form.startDate

      form.condition = {}
      if (this.form.type === 'by_week_days')
        form.condition.daysOfWeek = Object.keys(this.form.byWeekDays).filter(weekDay => this.form.byWeekDays[weekDay])
      else if (this.form.type === 'every_n_day')
        form.condition.repeatFromNDays = this.form.everyNDay

      delete form.everyNDay
      delete form.byWeekDays

      this.$emit('onSave', form)
    }
  }
}
</script>

<style scoped>

</style>