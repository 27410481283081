<template>
  <div>
    <b-col cols="12">
      <h2>Управление регулярными рейсами</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить регулярный рейс</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="periodicTrips" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left d-flex">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon v-if="data.item.isActive" class="action-icon" icon="pause-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Деактивировать"></b-icon>
                <b-icon v-else class="action-icon" icon="play-fill" variant="success" title="Активировать" @click="activate(data.item.id)"></b-icon>
              </div>
            </template>
            <template #cell(isActive)="data">
              <b v-if="data.item.isActive" class="text-success">Да</b>
              <b v-else class="text-danger">Нет</b>
            </template>
          </b-table>
          <b-pagination-nav
              :link-gen="linkGen"
              :value="page"
              @page-click="load"
              :number-of-pages="maxPages"
              use-router
          ></b-pagination-nav>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Create from './WorkWithPeriodicTrips/Create'
import Update from './WorkWithPeriodicTrips/Update'

export default {
  name: "WorkWithPeriodicTrips",
  data() {
    return {
      currentPage: 1,
      errors: {},
      fields: [
        {
          label: 'Перевозчик',
          key: 'carrier.carrierName'
        },
        {
          key: 'route',
          label: 'Маршрут',
          formatter: (value, key, item) =>  item.route.number + ' - ' + item.route.name
        },
        {
          key: 'startTime',
          label: 'Отправление',
          formatter: (value, key, item) => moment(item.startTime).format('HH:mm:ss')
        },
        {
          key: 'enabled',
          label: "Действителен",
          formatter: (value, key, item) => moment(item.startTime).format('DD.MM.YYYY') + ' - ' + ( item.finishTime ? moment(item.finishTime).format('DD.MM.YYYY') : '(неограниченно)')
        },
        {
          key: 'vehicle',
          label: "ТС",
          formatter: (value, key, item) => item.bus.mark + ' ' + item.bus.model + ' (' + item.bus.busSchema.seatsCount + ' мест)' + ' / ' + '(' + item.bus.gosNumber + ')'
        },
        {
          key: 'isActive',
          label: "Активен"
        },
        {
          key: 'actions',
          label: "Действия",
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('PeriodicTrips/load', { page: 1 })
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
      event.preventDefault()
      this.$store.dispatch('PeriodicTrips/load', { page })
    },
    update(form) {
      this.$refs.updateModal.show({
        ...form,
        startDate: moment(form.startTime).format('YYYY-MM-DD'),
        startTime: moment(form.startTime).format('HH:mm')
      })
    },
    remove(id) {
        console.log(id)
        if (!id)
          return

        if (!confirm('Вы действительно хотите деактивировать регулярный рейс?'))
          return

        this.$store.dispatch('App/startLoad')
        this.$store.dispatch('PeriodicTrips/remove', id)
            .then(() => {
              this.$bvToast.toast('Регулярный рейс был успешно деактивирован.', {
                title: 'Успех',
                autoHideDelay: 5000,
                variant: 'success',
                appendToast: true
              })
            })
            .finally(() => {
              this.$store.dispatch('App/stopLoad')
            })
    },
    activate(id) {
      console.log(id)
      if (!id)
        return

      if (!confirm('Вы действительно хотите активировать регулярный рейс?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('PeriodicTrips/activate', id)
          .then(() => {
            this.$bvToast.toast('Регулярный рейс был успешно активирован.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    }
  },
  computed: {
    ...mapState({
      loading: state => state.PeriodicTrips.loading,
      periodicTrips: state => state.PeriodicTrips.items,
      page: state => state.PeriodicTrips.page,
      maxPages: state => state.PeriodicTrips.maxPages
    })
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>

</style>