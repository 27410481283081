const SEATS_PATTERN = /^[\d,]*$/;

export default {
    carrierId: [{ required: true}],
    routeId: [{ required: true}],
    vehicleId: [{ required: true}],
    startTime: [{ required: true}],
    startDate: [{ required: true}],
    exceptSeats: [{ required: false }, { pattern: SEATS_PATTERN }],
    seatsOnly: [{ required: false }, { pattern: SEATS_PATTERN }],
    type: [{ required: true }],
    everyNDay: [{
        errorText: 'Заполните поле',
        customValidator: {
            callback: (value, form) => form.type !== 'every_n_day' ? true : !!value
        }
    }],
    byWeekDays: [{
        errorText: 'Должен быть выбран хотя бы один день',
        customValidator: {
            callback: (value, form) => form.type !== 'by_week_days' ? true : Object.values(value).some(day => day === true)
        }
    }]
}